import React, { useEffect, useState } from "react";
import API from "../../Api/api";
import { makeRzpPayment } from "../makeRzpPayment";
import { razorPayRedirectToContent } from "../utills/mobilehandlers";
import axios from "axios";
import paymentStyle from "../payment.module.css";
import Loader from "../loader";

const SubscriptionRzpPayment = ({
  accessToken,
  clientName,
  userDetails,
}: {
  accessToken: string;
  clientName: string;
  userDetails: any;
}) => {
  const [error, setError] = useState<string | null>(null);
  const subsDetails =
    new URLSearchParams(window.location.search).get("subsDetails") || undefined;
  const subscriptionContentDetails = JSON.parse(
    window.atob(subsDetails as string)
  );
  console.log(subscriptionContentDetails, "dsjfheioujio");

  const [loading, setLoading] = useState(true);
  const URLshortner = async (url: string) => {
    const data = await axios({
      url: `${process.env.REACT_APP_API_URL_V2}/url-shortner`,
      method: "POST",
      data: {
        url: url,
      },
    });
    return data.data;
  };
  useEffect(() => {
    document.title = subscriptionContentDetails.paymentDocumentTitle
      ? subscriptionContentDetails.paymentDocumentTitle
      : "Payments";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      //@ts-ignore
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    //@ts-ignore
    link.href = subscriptionContentDetails.brandlogoUrl;
  }, []);

  const rzrPayCrossBtn = async (transactionId: string) => {
    razorPayRedirectToContent("RZP_CROSS_BTN_CLICKED");
    const rzrPayCrossBtnObject = {
      eventType: "CLICK",
      eventLocation: "SUBS_PAYMENT_GATEWAY",
      firstTimeLogin: true,
      clickAction: "PAYMENT_GATEWAY_CROSS_BTN",
      amount: subscriptionContentDetails?.amount,
      currency: subscriptionContentDetails?.currency,
      userId: subscriptionContentDetails?.userId,
      clientId: subscriptionContentDetails?.clientId,
      clientContentId: subscriptionContentDetails?.clientContentId,
      anonId: subscriptionContentDetails?.anonId,
      paywallId: subscriptionContentDetails?.paywallId,
      paywallType: subscriptionContentDetails?.paywallType,
      pageType: subscriptionContentDetails?.pageType,
    };
    try {
      navigator.sendBeacon(
        `${process.env.REACT_APP_KAFKA_URL}collect/event`,
        JSON.stringify({
          messageBody: [{ ...rzrPayCrossBtnObject }],
        })
      );
    } catch (error) {
      console.error("error while sending events", error);
    }
    const response = await API.payment.abortTransaction({
      id: transactionId,
      type: "ORDER",
      event: rzrPayCrossBtnObject,
      token: accessToken,
    });
    if (API.validators.checkSuccessCode(response)) {
      window.location.href = subscriptionContentDetails?.failureFrontendUrl;
    }
  };
  const paymentView = () => {
    const paymentviewObject = {
      // ...getDefaultTrackingValues(),
      eventType: "VIEW",
      eventLocation: "SUBS_PAYMENT_GATEWAY",
      subId: subscriptionContentDetails?.subscriptionId,
      tierId: subscriptionContentDetails?.tierId,
      landingPageId: subscriptionContentDetails?.landingPageId,
      landingPageTitle: subscriptionContentDetails?.landingPageTitle,
      amount: subscriptionContentDetails?.amount,
      currency: subscriptionContentDetails?.currency,
      clientId: subscriptionContentDetails?.clientId,
      contentId: subscriptionContentDetails?.clientContentId,
      userId: subscriptionContentDetails?.userId,
      anonId: subscriptionContentDetails?.anonId,
      paywallId: subscriptionContentDetails.paywallId,
      purchaseType: "SUBSCRIPTION",
      pageType: subscriptionContentDetails.pageType,
    };
    try {
      navigator.sendBeacon(
        `${process.env.REACT_APP_KAFKA_URL}collect/event`,
        JSON.stringify({
          messageBody: [{ ...paymentviewObject }],
        })
      );
    } catch (error) {
      console.error("error while sending events", error);
    }
  };

  const intializeRazorpay = async () => {
    paymentView();
    const postRenewalPrams = {
      clientId: subscriptionContentDetails?.clientId,
      subscriptionId: subscriptionContentDetails?.subscriptionId,
      tierId: subscriptionContentDetails?.tierId,
      type: "SUBSCRIPTION",
      contentDetails: subscriptionContentDetails,
      couponCode: subscriptionContentDetails?.couponCode,
      consumeFreeTrial: subscriptionContentDetails.consumeFreeTrial,
      anonId: subscriptionContentDetails?.anonId,
    };

    const rzrPayCrossBtnRebewal = async (transactionId: string) => {
      const rzrPayCrossBtnObject = {
        eventType: "CLICK",
        eventLocation: "SUBS_PAYMENT_GATEWAY",
        clickAction: "PAYMENT_GATEWAY_CROSS_BTN",
        subId: subscriptionContentDetails?.subscriptionId,
        tierId: subscriptionContentDetails?.tierId,
        amount: subscriptionContentDetails?.amount,
        currency: subscriptionContentDetails?.currency,
        clientId: subscriptionContentDetails?.clientId,
        contentId: subscriptionContentDetails?.clientContentId,
        landingPageId: subscriptionContentDetails?.landingPageId,
        landingPageTitle: subscriptionContentDetails?.landingPageTitle,
        userId: subscriptionContentDetails?.userId,
        anonId: subscriptionContentDetails?.anonId,
        paywallId: subscriptionContentDetails?.paywallId,
        paywallType: subscriptionContentDetails?.paywallType,
        pageType: subscriptionContentDetails?.pageType,
      };
      try {
        navigator.sendBeacon(
          `${process.env.REACT_APP_KAFKA_URL}collect/event`,
          JSON.stringify({
            messageBody: [{ ...rzrPayCrossBtnRebewal }],
          })
        );
      } catch (error) {
        console.error("error while sending events", error);
      }
      const x = await API.payment.abortTransaction({
        id: transactionId,
        type: "RENEWAL",
        event: rzrPayCrossBtnObject,
        token: accessToken,
      });
      if (API.validators.checkSuccessCode(x as any)) {
        window.location.href = subscriptionContentDetails?.failureFrontendUrl;
      }
    };

    if (subscriptionContentDetails?.renewSubscription) {
      try {
        const postreneweldata = await API.user.postRenewal({
          ...postRenewalPrams,
          userToken: accessToken,
          paywallId: subscriptionContentDetails.paywallId,
          paywallType: subscriptionContentDetails.paywallType,
        });
        if (!API.validators.checkSuccessCode(postreneweldata)) {
          setLoading(false);
          alert("Failed to authorize razorpay renewal");
          return;
        }
        const rzpSubscriptionId = postreneweldata.data.data.rzpSubscriptionId;
        const primaryColor = postreneweldata.data.data.color;
        const brandLogoUrl = postreneweldata.data.data.logoUrl;
        const failureUrl = await URLshortner(
          subscriptionContentDetails?.failureFrontendUrl
        );
        const redirectUrl = window.btoa(
          subscriptionContentDetails.successFrontendUrl +
            `renewalId=${postreneweldata.data.data.renewalId}&viewId=${subscriptionContentDetails?.viewId}&clientId=${subscriptionContentDetails?.clientId}&clientContentId=${subscriptionContentDetails?.clientContentId}&failureUrl=${failureUrl}`
        );
        const arrayToken = accessToken.split(".");
        const tokenPayload = JSON.parse(atob(arrayToken[1]));
        const transactionId = postreneweldata.data.data.renewalId;
        const options = {
          key: postreneweldata.data.data.publishableKey,
          subscription_id: rzpSubscriptionId,
          notes: {
            rzpSubscriptionId,
          },
          name: clientName,
          image:
            brandLogoUrl ||
            "https://conscent-static-assets.s3.ap-south-1.amazonaws.com/assets/conscent+logo.png",
          prefill: {
            // userDetails.phoneNumber,
            // userDetails.email,
            contact: userDetails.phoneNumber,
            email: userDetails.email,
          },
          redirect: true,
          modal: {
            confirm_close: false,
            ondismiss: function () {
              rzrPayCrossBtnRebewal(transactionId);
            },
          },
          callback_url: `https://api.conscent.art/api/v1/payment/gateway/rzpRedirect/?redirectUrl=${redirectUrl}`,
          theme: {
            color: primaryColor || "#1a92b4",
          },
        };
        // @ts-ignore
        const rzp = new window.Razorpay(options);
        setLoading?.(false);
        rzp.open();
      } catch (error: any) {
        setLoading(false);
        if (error?.response) {
          if (error.response.status === 400 || error.response.status === 500) {
            setError(error.response.data.message);
          } else {
            setError("An unexpected error occurred. Please try again later.");
          }
        } else {
          setError("An unexpected error occurred. Please try again later.");
        }
      }
    } else {
      try {
        subscriptionContentDetails.accessToken = accessToken;

        const pgIntegrationResponse = await API.payment.pgIntegration(
          subscriptionContentDetails
        );
        if (!API.validators.checkSuccessCode(pgIntegrationResponse)) {
          return;
        } else {
          const transactionid =
            pgIntegrationResponse?.data?.data?.transactionId;
          const pgData = pgIntegrationResponse.data.data as {
            rzpOrderId: string;
            publishableKey: string;
            logoUrl: string;
            color: string;
          };
          if (!pgData.rzpOrderId) return;
          const failureUrl = await URLshortner(
            subscriptionContentDetails?.failureFrontendUrl
          );

          const redirectUrl = `${subscriptionContentDetails.successFrontendUrl}transactionId=${transactionid}&viewId=${subscriptionContentDetails?.viewId}&clientId=${subscriptionContentDetails?.clientId}&clientContentId=${subscriptionContentDetails?.clientContentId}&failureUrl=${failureUrl}`;
          const arrayToken = accessToken.split(".");
          const tokenPayload = JSON.parse(atob(arrayToken[1]));
          console.log(tokenPayload, "djhiuerhfioejro");
          makeRzpPayment(
            pgData.rzpOrderId,
            pgData.color,
            pgData.logoUrl,
            userDetails.phoneNumber,
            userDetails.email,
            rzrPayCrossBtn,
            setLoading,
            pgData.publishableKey,
            redirectUrl,
            transactionid,
            clientName
          );
        }
      } catch (error: any) {
        setLoading(false);
        if (error?.response) {
          if (error.response.status === 400 || error.response.status === 500) {
            setError(error.response.data.message);
          } else {
            setError("An unexpected error occurred. Please try again later.");
          }
        } else {
          setError("An unexpected error occurred. Please try again later.");
        }
      }
    }
  };

  useEffect(() => {
    intializeRazorpay();
  }, []);
  return (
    <>
      <div className={paymentStyle["main-container"]}>
        {loading && (
           <Loader />
        )}
        {error && <div className={paymentStyle["error-message"]}>{error}</div>}
      </div>
    </>
  );
};

export default SubscriptionRzpPayment;
